import React, { useContext, useState, useEffect } from "react";
import { PathName } from "../../constants/PathName";
import { UserContext } from "../../contexts/UserContext";
import useQueryParams from "../../hooks/useQueryParams";
import "./search.scss";
import {
	buildDescription,
	buildUrl,
	calculate_top_margin,
	getResInitiative,
	getResultProduct,
	isSearchQuouted,
	listOfInitiativeSlugs,
	listOfSearchPostTypes,
	validateSearch,
} from "../../utils/utils";
import { useHistory } from "react-router";
import ParseHTMLContent from "../../components/ParseHTMLContent/ParseHTMLContent";
import { useGetSearchPostsLazyQuery } from "../../generated/graphql";
import Loading from "../../components/Loading/Loading";
import { DisplaySearchForm } from "../../components/SearchForm/SearchForm";
import ParseUrlTarget from "../../components/ParseUrlTarget/ParseUrlTarget";

const Search = () => {
	const PAGE_SIZE = 25;
	const query = useQueryParams();
	const { store }: any = useContext(UserContext);
	const {
		translation,
		userLanguage,
		personID,
		permissionGroup: { accessLevel, companyType, customRole },
	} = store;
	const history = useHistory();
	const [searchValue, setSearchValue] = useState("");
	const [isSearchValid, setSearchValid] = useState(true);
	const [currentpage, setCurrentPage] = useState(1);
	const [offset, setOffset] = useState(0);

	const searchTerm = query.get("q");

	const module = query.get("m") !== "null" ? query.get("m") : null;

	const msub = query.get("msub");

	const [getSearchPosts, getSearchPostsRes] = useGetSearchPostsLazyQuery();

	useEffect(() => {
		getSearchPosts({
			variables: {
				language: userLanguage,
				limit: PAGE_SIZE,
				offset: offset,
				post_type: listOfSearchPostTypes,
				search_value: searchTerm,
				initiative: listOfInitiativeSlugs,
				accept_access_role: accessLevel,
				accept_company_type: companyType,
				accept_custom_role: customRole,
				user_eid: personID,
			},
		});
	}, [
		getSearchPosts,
		searchTerm,
		userLanguage,
		offset,
		accessLevel,
		companyType,
		customRole,
		personID,
	]);

	useEffect(() => {
		if (searchValue !== null && searchValue !== undefined) {
			let cleanTerm = searchValue;
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
			const termArray = cleanTerm.split(/\s+/);
			const isInvalid = termArray.some((term) => {
				return !validateSearch(term, userLanguage);
			});

			if (!isInvalid) {
				setSearchValid(true);
			} else {
				setSearchValid(false);
			}
		}
	}, [searchValue, userLanguage]);

	const submitSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		if (!isSearchValid) {
			return false;
		}

		const isQuoted = isSearchQuouted(searchValue);
		let cleanTerm = searchValue;
		if (isQuoted) {
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
		}

		const querySearch = isQuoted ? "'" + cleanTerm + "'" : cleanTerm;

		history.push(
			PathName.DASHBOARD +
				"mcd-search/" +
				"?q=" +
				querySearch +
				"&m=" +
				module +
				"&msub=" +
				msub +
				"&l=" +
				userLanguage,
		);
		clearSearch();
	};

	const clearSearch = () => {
		setSearchValue("");
	};

	const totalPosts = getSearchPostsRes.data?.getSearchPosts?.found_posts ?? 0;
	const limit = getSearchPostsRes.variables?.limit || 0;
	const maxNumPages = getSearchPostsRes.data?.getSearchPosts?.max_num_pages ?? 0;

	return (
		<div className="container-fluid main" style={{ ...calculate_top_margin() }}>
			{getSearchPostsRes.loading && <Loading />}
			<div className="row">
				{!searchTerm && (
					<DisplaySearchForm
						isLoading={getSearchPostsRes.loading}
						isSearchValid={isSearchValid}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						submitSearch={submitSearch}
						translation={translation}
					/>
				)}
				{searchTerm && (
					<>
						<div>
							<DisplaySearchForm
								isLoading={getSearchPostsRes.loading}
								isSearchValid={isSearchValid}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								submitSearch={submitSearch}
								translation={translation}
							/>
						</div>
						<div className="col-xs-12">
							<h1>
								<ParseHTMLContent
									htmlString={translation("Search Results", "Resultados de B&uacute;squeda")}
								/>
							</h1>
							{getSearchPostsRes.loading && <p className="loading-text">Loading...</p>}
							{!getSearchPostsRes.loading && (
								<p>
									{totalPosts} {translation("Results found for", "Resultados encontrados para")}{" "}
									<em>{searchTerm}</em>
								</p>
							)}
							{totalPosts > limit && (
								<div className="pagination-info">
									Showing: {offset + 1} - {offset + PAGE_SIZE}
								</div>
							)}
						</div>
						<div className="col-xs-12 last">
							{getSearchPostsRes.data?.getSearchPosts?.posts?.map((post, i) => {
								const initiative = getResInitiative(post?.attributes?.initiative ?? "");
								const slug = getResInitiative(post?.initiatives?.[0]?.slug ?? "");
								const product = getResultProduct(post?.attributes?.product, userLanguage);
								const url = buildUrl(post, PathName, userLanguage);
								const description = buildDescription(post?.attributes, translation);

								return (
									<React.Fragment key={i}>
										<div className="result">
											<div>
												<h3>
													<div>
														<small>
															{(initiative ? initiative : slug) +
																" > " +
																(product ? product + " > " : "")}
														</small>
													</div>
													<div>
														<ParseUrlTarget url={url} target="_blank">
															{/* Access current post's url */}
															<ParseHTMLContent
																htmlString={translation(
																	post?.attributes?.title,
																	post?.attributes?.title_es,
																)}
															/>
															{/* Access current post's title */}
														</ParseUrlTarget>
													</div>
												</h3>
											</div>
											<div>
												<p>
													<ParseHTMLContent htmlString={description} />
													{/* Pass the truncated description */}
												</p>
											</div>
											<hr />
										</div>
									</React.Fragment>
								);
							})}

							{/** If nothing is found **/}
							{totalPosts === 0 && getSearchPostsRes.loading === false && (
								<p>
									{translation(
										"Sorry, coudn't find anything. Please try again.",
										"Lo siento, no encontramos nada. Por favor intenta de nuevo.",
									)}
								</p>
							)}
						</div>
						{totalPosts > limit && (
							<div className="col-xs-12 ">
								<div className="pagination-info">
									Showing: {offset + 1} - {offset + PAGE_SIZE}
								</div>
								<div className="pagination-container">
									{[...Array(maxNumPages)].map((v, i) => {
										return (
											<div
												key={"pag-" + i}
												className={"pagination-item " + (currentpage === i + 1 ? "active" : "")}
												onClick={() => {
													setOffset(i * PAGE_SIZE);
													setCurrentPage(i + 1);
												}}
											>
												{i + 1}
											</div>
										);
									})}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default Search;
